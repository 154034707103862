.UserWelcomeIntroFullPage {
    min-height: 100vh;
}

.UserWelcomeIntroFullPageContain,
.UserWelcomeIntroFullPageContain2 {
    padding-block: 8vh;
    padding-inline: 5%;
    max-width: 700px;
    text-align: center;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 100vh;
}

.UserWelcomeIntroLogoImage {
    width: 100%;
    max-width: 500px;
    margin-inline: auto;
    height: auto;
    margin-block-start: 2vh;
}

.UserWelcomeIntroTexts {
    font-family: 'Meltmino', sans-serif;
    color: #6633FF;
}

.UserWelcomeIntroTitle {
    font-weight: 600;
    font-size: 5vh;
    padding-block-end: 1rem;
    line-height: 40px;
    letter-spacing: 1px;
}

.UserWelcomeIntroSubtitle {
    font-weight: 700;
    font-size: 1.1rem;
    padding-inline: 2%;
    padding-block-end: 2vh;
}

.UserWelcomeIntroPageCounterLines {
    display: flex;
}

.UserWelcomeIntroPageCounterLine,
.UserWelcomeIntroPageCounterLinePurple {
    height: 8px;
    border-radius: 4px;
    background-color: #FBFFFF;
    width: 100%;
    margin-inline: 2px;
}

.UserWelcomeIntroPageCounterLinePurple {
    background-color: #6633FF;
}

.UserWelcomeIntroButtonFlex {
    display: flex;
    justify-content: space-between;
    margin-block-start: 2vh;
}

.UserWelcomeIntroEndButton {
    justify-content: center;
    align-items: center;
}

.UserWelcomeIntroFullPageContain2 {
    padding-block-start: 0px;
}

.UserWelcomeIntroMainImage {
    width: 75%;
    min-width: 260px;
    height: auto;
    margin-inline: auto;
    text-align: center;
    margin-block-start: 2vw;
    padding-inline: 2%;
}

@media (max-width:420px) {
    .UserWelcomeIntroButtonFlex {
        flex-flow: row wrap;
        gap: 1rem;
    }

    .UserWelcomeIntroEndButton {
        width: 100%;
    }

    .UserWelcomeIntroFullPageContain,
    .UserWelcomeIntroFullPageContain2 {
        justify-content: space-between;
    }
}