.UserWelcome {
    background-color: #EFEFFF;
    height: 100%;
    min-height : 100vh;
    padding-inline: 2%;
    padding-block: 2rem;
}

.UserWelcomeBlock {
    max-width: 1140px;
    margin-inline: auto;
    background-color: white;
    border-radius: 15px;
    border: 1px solid #BFD4E4;
    text-align: center;
    padding-block: 10%;
    padding-inline: 2%;
    color: #6633FF;
    margin-block-start: var(--topPageMargin);
}

.UserWelcomeBlockUpTitle {
    font-family: 'Meltmino', sans-serif;
}

.UserWelcomeBlockTitle {
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: 'Meltmino', sans-serif;
}

.UserWelcomeBlockSubtitle {
    font-size: 1.1rem;
    padding-inline: 2%;
    max-width: 30rem;
    margin-inline: auto;
    margin-block-start: 1.5rem;
}

.triangle {
    --active: #fff;
    --border: rgba(255, 255, 255, .12);
    display: block;
    position: relative;
    width: 22px;
    height: 22px;
    margin-block: auto;
    &.left {
        transform: scaleX(-1);
    }
    .innerarrowPurple {
        display: block;
        position: absolute;
        margin: -10px 0 0 -10px;
        width: 20px;
        height: 20px;
        left: 50%;
        top: 50%;
        &:before,
        &:after {
            content: '';
            width: 10px;
            height: 2px;
            border-radius: 1px;
            position: absolute;
            left: 50%;
            top: 50%;
            background: var(--active);
            margin: -1px 0 0 -5px;
            display: block;
            transform-origin: 9px 50%;
            background: #6633FF;
        }
        &:before {
            transform: rotate(-40deg);
        }
        &:after {
            transform: rotate(40deg);
        }
    }
    .innerarrowWhite {
        display: block;
        position: absolute;
        margin: -10px 0 0 -10px;
        width: 20px;
        height: 20px;
        left: 50%;
        top: 50%;
        &:before,
        &:after {
            content: '';
            width: 10px;
            height: 2px;
            border-radius: 1px;
            position: absolute;
            left: 50%;
            top: 50%;
            background: var(--active);
            margin: -1px 0 0 -5px;
            display: block;
            transform-origin: 9px 50%;
            background: white;
        }
        &:before {
            transform: rotate(-40deg);
        }
        &:after {
            transform: rotate(40deg);
        }
    }
    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 1px;
        right: 1px;
        top: 1px;
        bottom: 1px;
        border-radius: 50%;
    }
}

/* Page 1 */
.UserWelcomeBlockPage1,
.UserWelcomeBlockPage2 {
    max-width: 1140px;
    margin-inline: auto;
    background-color: white;
    border-radius: 15px;
    border: 1px solid #BFD4E4;
    padding-block: 1rem;
    padding-inline: 1rem;
    color: #330066;
    margin-block-start: 110px;
}

.UserWelcomeBlockUpBanner {
    background-color: #EFEFFF;
    display: flex;
    border-radius: 15px;
    padding-block: 1rem;
    padding-inline: 2rem;
}

.UserWelcomeBlockUpBannerTextsYMCIcon {
    height: 50px;
    width: auto;
    margin-block: auto;
    margin-inline-end: 2rem;
}

.UserWelcomeBlockUpBannerTitle {
    color: #6633FF;
    font-size: 2.5rem;
    font-weight: 700;
    font-family: 'Meltmino', sans-serif;
}

.UserWelcomeBlockUpBannerSubtitle {
    font-size: var(--generalText);
    color: #330066;
}

.UserWelcomeBlockPageTabsFlex {
    display: flex;
    margin-block: 0.5rem;
    color: #6633FF;
}

.UserWelcomeBlockPageTab {
    height: 8px;
    background-color: #CFF9FF;
    width: 100%;
    margin-inline-end: 0.5rem;
    border-radius: 4px;
    margin-block: auto;
}

.UserWelcomeBlockPageTabActive {
    background-color: #6633FF;
}

.UserWelcomeBlockPageNumber {
    margin-inline-start: 1rem;
}

.UserWelcomeBlockPage1TitleFlex {
    display: flex;
    justify-content: space-between;
    margin-block-start: 1rem;
    max-width: 800px;
    margin-inline: auto;
}

.UserWelcomeBlockPage1Title {
    font-size: 2rem;
    color: #6633FF;
    font-weight: 600;
    text-align: left;
    margin-block: auto;
    font-family: 'Meltmino', sans-serif;
}

.UserWelcomeBlockPage1TitlePersonIcon {
    width: 50px;
    height: auto;
    margin-block: auto;
}

.UserWelcomeBlockPage1Subtitle {
    font-size: var(--epigraphText);
    margin-block-start: 2rem;
    max-width: 800px;
    margin-inline: auto;
    font-family: 'Meltmino', sans-serif;
}

.UserWelcomeBlockPage1Subtitle2 {
    font-size: 1.5rem;
    color: #6633FF;
    font-weight: 500;
    text-align: left;
    margin-block: auto;
    font-family: 'Meltmino', sans-serif;
    max-width: 800px;
    padding-inline-end: 2rem;
    margin-block-start: 2rem;
    margin-inline: auto;
}

.UserWelcomeBlockQuestionFlex {
    display: flex;
    justify-content: space-between;
    margin-block: 1rem;
    max-width: 800px;
    margin-inline: auto;
}

.UserWelcomeBlockQuestion {
    width: 48%;
}

.UserWelcomeBlockQuestionText {
    font-size: var(--generalText);
    margin-block-end: 0.5rem;
}

.UserWelcomeBlockQuestionInputFlex {
    display: flex;
}

.UserWelcomeBlockQuestionInputTile {
    width: 100%;
    padding-inline: 0.5rem;
    font-size: var(--buttonText);
    padding-block: 0.5rem;
}

.UserWelcomeBlockQuestionInputMeasure {
    padding-inline-start: 0.5rem;
    margin-block: auto;
}

.UserWelcomeBlockPageMaxWidth {
    max-width: 800px;
    margin-inline: auto;
    padding-block: 1rem;
}

.UserWelcomeBlockNextButtonFlex {
    display: flex;
    justify-content: space-between;
    margin-block: 1rem;
    max-width: 800px;
    margin-inline: auto;
}

.UserWelcomeBlockYNQuestionContainer,
.UserWelcomeBlockRangeQuestionContainer {
    margin-block: 2rem;
    max-width: 800px;
    margin-inline: auto;
}

.UserWelcomeBlockYNQuestion,
.UserWelcomeBlockRangeQuestion {
    font-size: var(--epigraphText);
    margin-block-end: 0.5rem;
    font-family: 'Meltmino', sans-serif;
}

.UserWelcomeBlockYNTileFlex {
    display: flex;
    max-width: 500px;
}

.UserWelcomeBlockYNTile {
    width: 12rem;
    margin-inline-end: 1rem;
    text-align: center;
}

.UserWelcomeBlockYNTileIncomplete {
    border: red solid 1px;
}

.UserWelcomeBlockYNQuestionAlert {
    text-align: center;
    font-size: var(--generalText);
}

.UserWelcomeBlockYNQuestionAlertHidden {
    display: none;
}

.UserWelcomeBlockRangeTileFlex {
    display: flex;
    max-width: 800px;
    margin-inline: auto;
}

.UserWelcomeBlockRangeInputTile {
    cursor: pointer;
    margin-block: auto;
}

.UserWelcomeBlockRangeTile {
    width: 5rem;
    text-align: center;
}

.UserWelcomeBlockNextButton:hover {
    .innerarrowWhite {
        &:before,
        &:after {
            background: #6633FF;
        }
    }
}

input[type="range"] {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    margin-right: 10px;
    border-radius: 6px;
    outline: 0;
    background: #CCCCFF;
    touch-action: none
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 25px;
    width: 31px;
    background: #6633FF;
    border-radius: 16px;
    border: 0;
    cursor: pointer;
  }

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
    height: 18px;
    width: 18px;
    border-radius: 3px;
    background: #6633FF;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
height: 18px;
width: 18px;
border-radius: 3px;
background: #6633FF;
border-radius: 50%;
border: 0;
cursor: pointer;
}

@media (max-width:540px) {
    .UserWelcomeBlockQuestionFlex {
        flex-flow: row wrap;
    }

    .UserWelcomeBlockQuestion {
        width: 100%;
        margin-block-end: 2rem;
    }

    .UserWelcomeBlockNextButtonFlex {
        flex-flow: row wrap-reverse;
        gap: 1rem;
    }

    .UserWelcomeBlockPageButton {
        width: 100%;
    }
}

@media (max-width:350px) {
    .UserWelcomeBlockUpBannerTextsYMCIcon {
        display: none;
    }
}