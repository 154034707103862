.WeAreYMCDiv {
    color: #330066;
}

/* Somos YMC */
.WeAreYMCFirstBlockDiv {
    width: 100%;
    padding-block-start: var(--topPageMargin);
    background-color: #6633FF;
    padding-block-end: 1rem;
    border-bottom-right-radius: 10vw;
}

.WeAreYMCFirstBlockInnerDiv {
    display: flex;
    margin-inline: auto;
    justify-content: space-between;
    max-width: 1300px;
    padding-inline: 3%;
}

.WeAreYMCFirstBlockLeft {
    width: 50%;
    margin-block: 2rem;
    margin-block-end: 4rem;
}

.WeAreYMCDidYouKnowUpTitle,
.WeAreYMCOurMissionUpTitle,
.WeAreYMCOurSolutionsUpTitle,
.WeAreYMCHealthRouteUpTitle,
.WeAreYMCOurJourneyUpTitle {
    background-color: #CFF9FF;
    width:fit-content;
    padding-block: 0.75rem;
    padding-inline: 1rem;
    border-radius: 10px;
    letter-spacing: 1px;
    font-size: 100%;
    text-align: center;
}

.WeAreYMCFirstBlockLeftUpTitle {
    color: #FBFFFF;
    font-family: 'Meltmino', sans-serif;
    font-weight: 600;
}

.WeAreYMCFirstBlockLeftTitle {
    font-weight: 600;
    margin-block: 1rem;
    color: #FBFFFF;
    font-family: 'Meltmino', sans-serif;
}

.WeAreYMCFirstBlockLeftSubtitle {
    font-size: var(--epigraphText);
    font-family: 'Meltmino', sans-serif;
    color: #FBFFFF;
    max-width: 30rem;
    letter-spacing: 1px;
}

.WeAreYMCFirstBlockLeftButtonsDiv {
    display: flex;
}

.WeAreYMCFirstBlockLeftButtonRight {
    padding-inline: 50px;
    color: #330066;
    margin-block-start: 2rem;
}

.WeAreYMCFirstBlockLeftButtonLeft {
    margin-block: 1rem;
    padding-inline: 50px;
    font-weight: 500;
    letter-spacing: 0px;
}

.WeAreYMCFirstBlockRight {
    width:50%;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    margin-block: auto;
    padding-block-start: 6rem;
}

.WeAreYMCFirstBlockRightLogo {
    max-width: 90%;
    height: auto;
    position: absolute;
    right: 0%;
}

/* Logos */
.WeAreYMCFirstBlockTrustedLogosDiv {
    padding-block: 2.5rem;
    letter-spacing: 1px;
    text-align: center;
    background: rgb(207,249,255);
    background: linear-gradient(90deg, rgba(207,249,255,1) 0%, rgba(204,204,255,1) 100%);
}

.WeAreYMCFirstBlockTrustedLogosFlex {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-block: 1.5rem;
    margin-inline: auto;
    max-width: 1140px;
}

.WeAreYMCFirstBlockTrustedLogosTitle {
    font-size: 1.3rem;
    font-weight: 500;
    font-family: 'Meltmino', sans-serif;
    text-align: left;
    padding-left: 10%;
}

.WeAreYMCFirstBlockTrustedLogosBlock {
    width: 25%;
    margin-block: auto;
}

.WeAreYMCFirstBlockTrustedLogosImage {
    width: 70%;
    height: auto;
}

.WeAreYMCFirstBlockTrustedLogosText {
    padding-block: 0.5rem;
    font-size: 0.9rem;
}
/* Problem block*/
.WeAreYMCProblemFlex{
    width: 100%;
    margin-block-start: 6rem;
}

.WeAreYMCProblemLeftBlock{
    width: 40%;
    margin-block: auto;
    text-align: center;
}

.WeAreYMCProblemLeftBlockImage{
    width: 100%;
    height: auto;
    max-width: 400px;
}

.WeAreYMCProblemRightBlock{
    width: 60%;
}

.WeAreYMCProblemRightBlockUpTitle{
    font-family: 'Meltmino', sans-serif;
    color: #6633FF;
    font-weight: 600;
    text-align: left;
}

.WeAreYMCProblemRightBlockTitle{
    font-weight: 700;
    margin-block: 1rem;
    color: #330066;
    font-family: 'Meltmino', sans-serif;
    text-align: left;
}

.WeAreYMCProblemRightBlockSubtitle{
    font-size: var(--epigraphText);
    font-family: 'Meltmino', sans-serif;
    color: #330066;
    max-width: 700px;
    text-align: left;
}

/* Nuestra misión */

.WeAreYMCOurMissionFlex{
    width: 100%;
    background-color: #EFEFFF;
    padding-block: 4rem;
}

.WeAreYMCOurMissionLeftBlock{
    width: 40%;
    text-align: center;
    margin-block: auto;
}

.WeAreYMCOurMissionLeftBlockImage{
    width: 80%;
    height: auto;
}

.WeAreYMCOurMissionRightBlock{
    width: 60%;
}

.WeAreYMCOurMissionRightBlockUpTitle{
    font-family: 'Meltmino', sans-serif;
    color: #6633FF;
    font-weight: 700;
    text-align: left;
}

.WeAreYMCOurMissionRightBlockTitle{
    font-weight: 700;
    margin-block: 1rem;
    color: #6633FF;
    font-family: 'Meltmino', sans-serif;
}

.WeAreYMCOurMissionRightBlockSubtitle{
    font-size: var(--epigraphText);
    font-family: 'Meltmino', sans-serif;
    color: #6633FF;
    max-width: 39rem;
}

.WeAreYMCOurMissionRightBlockSmallerSubtitle{
    font-size: 0.8rem;
    color: #6633FF;
    max-width: 23rem;
}

/* Nuestras soluciones */
.WeAreYMCSolutionsFlex{
    width: 100%;
    padding-block: 4rem;
}

.WeAreYMCSolutionsLeftBlock,
.WeAreYMCSolutionsRightBlock {
    width: 49%;
}

.WeAreYMCSolutionsLeftBlockFirstBlockUpTitle,
.WeAreYMCSolutionsLeftBlockSecondBlockUpTitle,
.WeAreYMCSolutionsRightBlockSecondBlockUpTitle,
.WeAreYMCSolutionsRightBlockThirdBlockUpTitle {
    color:#6633FF;
    font-family: 'Meltmino', sans-serif;
    font-weight: 500;
}

.WeAreYMCSolutionsLeftBlockFirstBlockTitle {
    color:#6633FF;
    font-family: 'Meltmino', sans-serif;
    font-size: 2.7rem;
    font-weight: 700;
    line-height: 3.5rem;
    padding-block-start: 1rem;
}

.WeAreYMCSolutionsLeftBlockSecondBlockTitle,
.WeAreYMCSolutionsRightBlockSecondBlockTitle,
.WeAreYMCSolutionsRightBlockThirdBlockTitle{
    color:#6633FF;
    font-family: 'Meltmino', sans-serif;
    font-size: 2.7rem;
    font-weight: 700;
    line-height: 3rem;
    padding-block-start: 1rem;
    max-width: 400px;
}

.WeAreYMCSolutionsLeftBlockFirstBlockSubtitle {
    color: #330066;
    padding-top: 3%;
    max-width: 25rem;
    font-size: var(--generalText);
}

.WeAreYMCSolutionsLeftBlockSecondBlock,
.WeAreYMCSolutionsRightBlockSecondBlock,
.WeAreYMCSolutionsRightBlockThirdBlock {
    border-radius: 16px;
    padding: 5%;
}

.WeAreYMCSolutionsLeftBlockSecondBlock {
    margin-block-start: 4rem;
    background-color: #CFF9FF;
    overflow: hidden;
}

.WeAreYMCSolutionsLeftBlockSecondBlockSubtitle,
.WeAreYMCSolutionsRightBlockSecondBlockSubtitle {
    color:#6633FF;
    font-family: 'Meltmino', sans-serif;
    font-weight: medium;
    max-width: 16rem;
    padding-block-start: 1rem;
}

.WeAreYMCSolutionsLeftBlockSecondBlockFlex,
.WeAreYMCSolutionsRightBlockSecondBlockFlex{
    display: flex;
    width: 100%;
}

.WeAreYMCSolutionsLeftBlockSecondBlockFlexText,
.WeAreYMCSolutionsLeftBlockSecondBlockFlexText2{
    color: #6633FF;
    font-family: 'Meltmino', sans-serif;
    margin-block-start: 15vw;
    width: 40%;
}

.WeAreYMCSolutionsLeftBlockSecondBlockFlexTextWhite {
    color: white;
}

.WeAreYMCSolutionsLeftBlockSecondBlockFlexText2{
    margin-block-start: 12vw;
}

.WeAreYMCSolutionsLeftBlockSecondBlockFlexText:hover,
.WeAreYMCSolutionsLeftBlockSecondBlockFlexText2:hover,
.WeAreYMCSolutionsLeftBlockSecondBlockFlexTextWhite:hover {
    cursor: pointer;
    color:#330066;
}

.WeAreYMCSolutionsLeftBlockSecondBlockFlexImageContainer {
    position: relative;
    width: 50%;
}

.WeAreYMCSolutionsLeftBlockSecondBlockFlexImage {
    position: absolute;
    width: 130%;
    height: auto;
    bottom: -2rem;
}

.WeAreYMCSolutionsLeftBlockSecondBlockFlexImage2 {
    position: absolute;
    width: 145%;
    height: auto;
    bottom: -4vw;
}

.WeAreYMCSolutionsLeftBlockThirdBlock {
    background-color: #6633FF;
    border-radius: 16px;
    margin-block-start: 1rem;
    padding: 5%;
    overflow: hidden;
}

.WeAreYMCSolutionsLeftBlockThirdBlockUpTitle {
    color:#FBFFFF;
    font-family: 'Meltmino', sans-serif;
    font-weight: medium;
}

.WeAreYMCSolutionsLeftBlockThirdBlockTitle {
    color:#FBFFFF;
    font-family: 'Meltmino', sans-serif;
    letter-spacing: 0.03rem;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 3.5rem;
    padding-right: 40%;
    padding-top: 3%;
}

.WeAreYMCSolutionsLeftBlockThirdBlockSubtitle {
    color:#FBFFFF;
    font-family: 'Meltmino', sans-serif;
    font-weight: medium;
    padding-right: 67%;
    padding-top: 2%;
}

.WeAreYMCSolutionsLeftBlockThirdBlockFlex {
    display: flex;
    width: 100%;
}

.WeAreYMCSolutionsLeftBlockThirdBlockFlexText {
    color: #FBFFFF;
    font-family: 'Meltmino', sans-serif;
    padding-top: 20%;
    width: 40%;
}

.WeAreYMCSolutionsLeftBlockThirdBlockFlexImageContainer {
    position: relative;
    width: 50%;
}

.WeAreYMCSolutionsLeftBlockThirdBlockFlexImage {
    position: absolute;
    width: 120%;
    height: auto;
    margin-top: -57%;
    margin-left: 11%;
}

.WeAreYMCSolutionsRightBlockFirstBlock {
    width: 100%;
}

.WeAreYMCSolutionsRightBlockFirstBlockImage {
    width: 100%;
    height: auto;
}

.WeAreYMCSolutionsRightBlockSecondBlock {
    background-color: #EFEFFF;
    margin-block-start: 1rem;
    overflow: hidden;
}

.WeAreYMCSolutionsRightBlockSecondBlockFlexText {
    color: #6633FF;
    font-family: 'Meltmino', sans-serif;
    padding-top: 25%;
    width: 40%;
}

.WeAreYMCSolutionsRightBlockSecondBlockFlexImageContainer {
    position: relative;
    width: 100%;
}

.WeAreYMCSolutionsRightBlockSecondBlockFlexImage {
    position: absolute;
    width: 105%;
    height: auto;
    margin-top: -32.5%;
    margin-left: 3%;
}

.WeAreYMCSolutionsRightBlockThirdBlock {
    background-color: #CCCCFF;
    margin-block-start: 1rem;
    overflow: hidden;
}

.WeAreYMCSolutionsRightBlockThirdBlockTitle {
    padding-right: 20%;
}

.WeAreYMCSolutionsRightBlockThirdBlockSubtitle {
    color:#6633FF;
    font-family: 'Meltmino', sans-serif;
    font-weight: medium;
    padding-right: 45%;
    padding-top: 2%;
}

.WeAreYMCSolutionsRightBlockThirdBlockFlexText {
    color: #6633FF;
    font-family: 'Meltmino', sans-serif;
    padding-top: 30%;
    width: 40%;
}

.WeAreYMCSolutionsRightBlockThirdBlockFlexImageContainer {
    position: relative;
    width: 100%;
}

.WeAreYMCSolutionsRightBlockThirdBlockFlexImage {
    position: absolute;
    width: 80%;
    height: auto;
    margin-top: -45%;
    margin-left: 30%;
}

/* Puedes confiar*/

.WeAreYMCYouCanTrustFlex {
    background-color: rgba(204, 204, 255, .5);
    padding-block: 60px;
}

.WeAreYMCYouCanTrustRightBlock {
    width: 30%;
    margin: auto;
}

.WeAreYMCYouCanTrustRightBlockImage {
    width: 100%;
    height: auto;
}

.WeAreYMCYouCanTrustLeftBlock {
    width: 70%;
}

.WeAreYMCYouCanTrustLeftBlockUpTitle {
    color: #330066;
    background-color: #CFF9FF;
    font-family: 'Meltmino', sans-serif;
    padding-inline: 17px;
    padding-block: 13px;
    border-radius: 10px;
    width: fit-content;
    text-align: center;
}

.WeAreYMCYouCanTrustLeftBlockTitle {
    font-family: 'Meltmino', sans-serif;
    margin-block-end: 50px;
    margin-block-start: 16px;
    font-weight: 700;
}

.WeAreYMCYouCanTrustLeftBlockFlex {
    display: flex;
    flex-flow: row wrap;
    max-width: 100%;
    gap: 20px;
}

.WeAreYMCYouCanTrustBlock {
    text-align: left;
    border: 1px solid #BFD4E4;
    border-radius: 16px;
    background-color: #FBFFFF;
    width: 30%;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.WeAreYMCYouCanTrustBlockImageContainer {
    height: 50px;
    width: 50px;
}

.WeAreYMCYouCanTrustBlockImage {
    height: 50px;
    width: 50px;
}

.WeAreYMCYouCanTrustBlockTitle {
    letter-spacing: 1px;
    font-size: 1.1rem;
    font-weight: 600;
    color: #330066;
}

.WeAreYMCYouCanTrustBlockSubtitle {
    font-size: var(--generalText);
    color: #330066;
    font-weight: 600;
    line-height: 17px;
}

/* Sabías que */
.WeAreYMCFirstBlockDidYouKnowDiv {
    padding-block: 5rem;
}

.WeAreYMCDidYouKnowUpTitle {
   margin-inline: auto;
}

.WeAreYMCFirstBlockDidYouKnowTextDiv {
    justify-items: center;
}

.WeAreYMCDidYouKnowTitle {
    font-size: 2.5rem;
    letter-spacing: 4px;
    line-height: 2.5rem;
    font-weight: 800;
    margin-block: 1rem;
    color: white;
    width: 55%;
    margin-inline: auto;
    text-align: center;
}

.WeAreYMCDidYouKnowSubtitle {
    font-size: 1rem;
    text-align: center;
    width: 50%;
    margin-inline: auto;
}

.WeAreYMCDidYouKnowButton {
    width:fit-content;
    margin-block: 1rem;
    padding-block: 1rem;
    padding-inline: 4rem;
    border-radius: 10px;
    font-size: 0.9rem;
    font-weight: 600;
    background-color: #6633FF;
    color: white;
    margin-inline: auto;
    border: 1px solid #9747FF;
}


/* Nuestra Misión */
/* .WeAreYMCOurMissionFlexDiv {
    display: flex;
    flex-flow: row wrap;
    margin-block: 5rem;
    margin-inline: auto;
    max-width: 1140px;
}

.WeAreYMCOurMissionLeftBlockDiv {
    width: 50%;
    margin-block: auto;
    text-align: center;
}

.WeAreYMCOurMissionLeftBlock {
    width: 100%;
    padding-inline: 10%;
    height: auto;
}

.WeAreYMCOurMissionRightBlock {
    margin-block: auto;
    width: 50%;
}

.WeAreYMCOurMissionTitle {
    font-size: 2.2rem;
    letter-spacing: 4px;
    line-height: 4vw;
    font-weight: 600;
    margin-block: 1rem;
    padding-inline-end: 5%;
} */

/* Ruta de YMC */
.WeAreYMCHealthRoute {
    text-align: center;
    margin-inline: 1%;
    margin-block: 2rem;
    margin-inline: auto;
    max-width: 1140px;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
}

.WeAreYMCHealthRouteUpTitle {
    margin-inline: auto;
    margin-block-end: 2rem;
}

.WeAreYMCHealthRouteRouteContainer {
    height: 30rem;
    overflow-x: scroll;
    border-radius: 30px;
}

/* Startup chilena */
.WeAreYMCStartupDiv {
    text-align: center;
}

.WeAreYMCStartupFirstBlock {
    background-color: #330066;
}

.WeAreYMCStartupDivTitleFlex {
    padding-block: 5%;
    gap: 20px;
    justify-content: space-between;
}

.WeAreYMCStartupTitle {
    width: 50%;
    font-weight: 400;
    font-family: 'Meltmino', sans-serif;
    text-align: left;
    color: #FBFFFF;
    font-size: 3rem;
    line-height: 3.3rem;
}

.WeAreYMCStartupSubtitle {
    width: 50%;
    color: #FBFFFF;
    font-family: 'Meltmino', sans-serif;
    font-weight: 500;
    margin-block: auto;
    text-align: left;
    max-width: 500px;
    letter-spacing: 2px;
}

.WeAreYMCStartupTeamImagesFlex {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    margin-block-start: 10%;
    align-items: flex-end;
}

.WeAreYMCStartupTeamTallImageBlock {
    width: 25%;
    position: relative;
    padding-bottom: 3%;
}

.WeAreYMCStartupTeamShortImageBlock {
    width: 25%;
    position: relative;
}

.WeAreYMCStartupTeamImageContainer,
.WeAreYMCStartupHackatonTeamImageContainer2 {
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    margin-top: -50%;
}

.WeAreYMCStartupTeamImage {
    width: 100%;
    height: auto;
    object-position: 0rem 0vw;
    border-radius: 50%;
}

.WeAreYMCStartupTeamImageSlightOffset {
    object-position: 0rem 1vw;
}

#CEO {
    background-color: #10DFFF;
}

#CEOBlock {
    background-color: #CFF9FF;
    height: 60%;
    padding-bottom: 5%;
}

#Team1 {
    background-color: #FBFFFF;
}

#Team2 {
    background-color: #EFEFFF;
}

#Team3,
#Team6 {
    background-color: #FFFAEA;
}

#Team4 {
    background-color: #CCCCFF;
}

#Team5 {
    background-color: #CFF9FF;
}

.WeAreYMCStartupTeamMemberName,
.WeAreYMCStartupTeamMemberName2 {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 600;
    margin-block: auto;
    font-family: 'Meltmino', sans-serif;
    padding-inline: 1%;
    padding-block-start: 60%;
    padding-block-end: 0.5rem;
}

.WeAreYMCStartupTeamMemberSpecialty {
    padding-inline: 1%;
    font-weight: 500;
    padding-block-end: 1rem;
}

.WeAreYMCStartupSecondBlock{
    padding-top: 4%;
    background-color: #EFEFFF;
}

/* Nuetra Trayectoria*/

.WeAreYMCOurJourney {
    padding-block: 5%;
    padding-inline: 2%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.WeAreYMCOurJourneyUpTitle {
    margin-inline: auto;
    font-size: var(--epigraphText);
}

.WeAreYMCOurJourneyTitle {
    font-family: 'Meltmino', sans-serif;
    color: #330066;
    text-align: center;
    max-width: 640px;
    margin-inline: auto;
}

.WeAreYMCOurJourneyEvents {
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.WeAreYMCOurJourneyFlex {
    display: flex;
    justify-items: center; 
    align-items: center;
    justify-content: center;
    max-width: 750px;
    margin-inline: auto;
    width: 100%;
    gap: 20px
}

.WeAreYMCOurJourneyYear {
    color:#28AEFF;
    font-family: 'Meltmino', sans-serif;
}

.WeAreYMCOurJourneyDate {
    width: 42.5%;
    color: #330066;
}

.WeAreYMCOurJourneyEventText {
    width: 42.5%;
    color: #330066;
}

#text-right {
    text-align: left;
}

#text-left {
    text-align: right;
}

.WeAreYMCOurJourneyIcon {
    width: 70px;
    height: auto;
}

.WeAreYMCOurJourneyIconBigger {
    width: 103px;
}

.WeAreYMCOurJourneyLine {
    margin-inline: 20%;
    background-color: #330066;
    color: #330066;
    border-color: #330066;
    height: 3px;
}

.WeAreYMCOurJourneyExpandFlex {
    display: flex;
    justify-content: center;
    padding-block: 0.5%;
    align-items: center;
    gap: 20px;
}

.WeAreYMCOurJourneyExpandFlexIcon {
    width: 50px;
    height: auto;
}

/* Hackaton Team*/
.WeAreYMCHackatonTeam {
    background-color: #10DFFF;
}

.WeAreYMCHackatonTeamTitleFlex {
    padding-block: 5%;
    gap: 20px;
    justify-content: space-between;
}

.WeAreYMCHackatonTeamTitle {
    color: #330066;
    font-weight: 610;
    font-family: 'Meltmino', sans-serif;
    width: 50%;
    text-align: left;
}

.WeAreYMCHackatonTeamSubtitle {
    color: #330066;
    font-weight: 500;
    font-family: 'Meltmino', sans-serif;
    text-align: left;
    width: 50%;
    margin-block: auto;
    max-width: 500px;
}

.WeAreYMCStartupHackatonTeamImagesFlex {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    margin-top: 13%;
    align-items: flex-end;
}

.WeAreYMCStartupHackatonTeamTallImageBlock {
    width: 20%;
    position: relative;
    padding-bottom: 8%;
}

.WeAreYMCStartupHackatonTeamShortImageBlock {
    padding-bottom: 5%;
    width: 20%;
    position: relative;
}

.WeAreYMCStartupHackatonTeamImageContainer2 {
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    margin-top: -70%;
}

/* Nuestras Soluciones */
.WeAreYMCOurSolutionsDiv {
    margin-block-start: 10rem;
    margin-block-end: 7.5rem;
    margin-inline: auto;
    max-width: 1140px;
}

.WeAreYMCOurSolutionsDivContainer {
    margin-inline: 5%;
}

.WeAreYMCOurSolutionsTitle {
    margin-block: 2rem;
    font-size: 2.5rem;
    letter-spacing: 2px;
}

.WeAreYMCOurSolutionsSubtitle {
    width: 25rem;
    font-size: 0.9rem;
}

.WeAreYMCOurSolutionsFlexedBlocks {
    display: flex;
    flex-flow: row wrap;
    margin-block: 3rem;
    justify-content: center;
}

.WeAreYMCOurSolutionsSolutionBlock {
    width: 24%;
    background-color: #D9D9D9;
    padding-block-start: 14rem;
    padding-block-end: 1.5rem;
    margin-inline: 0.5%;
}

.WeAreYMCOurSolutionsSolutionBlockButton {
    margin-inline: 10%;
    background-color: #6633FF;
    color: white;
    padding-block: 0.5rem;
    padding-inline: 5%;
    text-align: center;
    border-radius: 10px;
    font-size: 0.8rem;
    border: 1px solid #9747FF;
}

.WeAreYMCDidYouKnowButton:hover,
.WeAreYMCOurSolutionsSolutionBlockButton:hover {
    background-color: #dcb3ff;
    color: black;
    cursor: pointer;
    transition: all 0.3s;
}

.ScrollBackToTop {
    cursor: pointer;
}

@media (max-width: 1200px) {
    .WeAreYMCFirstBlockRight {
        padding-block-start: 0rem;
    }
}

@media (max-width: 1100px) {
    .WeAreYMCYouCanTrustRightBlock {
        display: none;
    }

    .WeAreYMCYouCanTrustLeftBlock {
        width: 100%;
    }

    .WeAreYMCYouCanTrustLeftBlockFlex {
        justify-content: space-between;
    }

    .WeAreYMCYouCanTrustBlock {
        width: 31%;
    }
}

@media (max-width: 820px) {
    .WeAreYMCFirstBlockInnerDiv {
        padding-inline: 2%;
    }

    .WeAreYMCFirstBlockLeft {
        width: 100%;
        text-align: center;
    }

    .WeAreYMCFirstBlockRight {
        display: none;
    }

    .WeAreYMCFirstBlockLeftSubtitle,
    .WeAreYMCFirstBlockLeftButtonRight {
        margin-inline: auto;
    }
    
    .WeAreYMCStartupTeamMemberName,
    .WeAreYMCStartupTeamMemberName2 {
        font-size: 0.9rem;
    }

    .WeAreYMCStartupDivTitleFlex,
    .WeAreYMCHackatonTeamTitleFlex {
        flex-flow: row wrap;
    }

    .WeAreYMCStartupTitle,
    .WeAreYMCStartupSubtitle,
    .WeAreYMCHackatonTeamTitle,
    .WeAreYMCHackatonTeamSubtitle {
        width: 100%;
        text-align: center;
        margin-inline: auto;
    }
}

@media (max-width: 720px) {
    .WeAreYMCOurSolutionsSolutionBlock {
        width: 47%;
        margin-block-end: 1rem;
    }

    .WeAreYMCOurMissionTitle {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }

    .WeAreYMCStartupTitle {
        font-weight: 600;
    }

    .WeAreYMCDidYouKnowUpTitle,
    .WeAreYMCOurMissionUpTitle,
    .WeAreYMCStartupUpTitle,
    .WeAreYMCOurSolutionsUpTitle {
        font-size: 1rem;
    }

    .WeAreYMCFirstBlockLeftTitle {
        font-size: 2rem;
        line-height: 2rem;
        padding-inline-end: 0rem;
    }

    .WeAreYMCFirstBlockTrustedLogosBlock {
        width: 45%;
        margin-block: 4rem;
        margin-inline: 2%;
    }
    
    .WeAreYMCDidYouKnowTitle,
    .WeAreYMCDidYouKnowSubtitle,
    .WeAreYMCStartupTitle {
        width: 90%;
    }
    
    .WeAreYMCProblemLeftBlock,
    .WeAreYMCProblemRightBlock {
        width: 100%;
        margin-inline: auto;
    }

    .WeAreYMCYouCanTrustLeftBlockUpTitle{
        margin-inline: auto;
    }
    
    .WeAreYMCProblemLeftBlockImage,
    .WeAreYMCOurMissionLeftBlockImage{
        width: 100%;
        max-width: 300px;
        margin-block-end: 2rem;
    }

    .WeAreYMCProblemRightBlockUpTitle,
    .WeAreYMCProblemRightBlockTitle,
    .WeAreYMCProblemRightBlockSubtitle,
    .WeAreYMCYouCanTrustLeftBlockTitle {
        text-align: center;
    }

    .WeAreYMCFirstBlockLeftButtonLeft,
    .WeAreYMCProblemRightBlockSubtitle {
        margin-inline: auto;
    }

    .WeAreYMCFirstBlockInnerDiv {
        flex-flow: row wrap;
    }

    .WeAreYMCSolutionsLeftBlock,
    .WeAreYMCSolutionsRightBlock,
    .WeAreYMCOurMissionLeftBlock,
    .WeAreYMCOurMissionRightBlock {
        width: 100%;
    }

    .WeAreYMCSolutionsLeftBlockFirstBlock {
        text-align: center;
    }

    .WeAreYMCOurMissionRightBlockUpTitle,
    .WeAreYMCOurMissionRightBlockTitle,
    .WeAreYMCOurMissionRightBlockSubtitle,
    .WeAreYMCOurMissionRightBlockSmallerSubtitle {
        text-align: center;
        margin-inline: auto;
    }

    .WeAreYMCSolutionsLeftBlockFirstBlockTitle {
        line-height: 2.5rem;
    }

    .WeAreYMCSolutionsLeftBlockFirstBlockSubtitle {
        margin-inline: auto;
    }

    .WeAreYMCSolutionsLeftBlockSecondBlockFlex {
        justify-content: space-between;
    }

    .WeAreYMCSolutionsLeftBlockSecondBlockFlexImage {
        padding-inline-start: 5rem;
        right: 0px
    }

    .WeAreYMCSolutionsRightBlockFirstBlock {
        display: none;
    }

    .WeAreYMCSolutionsLeftBlockSecondBlockFlexImage2 {
        position: absolute;
        width: 120%;
        height: auto;
        bottom: -2rem;
    }

    .WeAreYMCYouCanTrustBlock {
        width: 48%;
    }

    .WeAreYMCStartupSubtitle {
        margin-block-end: 2rem;
    }

    .WeAreYMCStartupTeamImagesFlex {
        margin-block: 0px;
    }
    
    .WeAreYMCStartupTeamTallImageBlock,
    .WeAreYMCStartupTeamShortImageBlock,
    .WeAreYMCStartupHackatonTeamTallImageBlock,
    .WeAreYMCStartupHackatonTeamShortImageBlock {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-block: 5%;
        padding-inline: 5%;
        border-top-left-radius: 12rem;
        border-bottom-left-radius: 12rem;
    }

    .WeAreYMCStartupHackatonTeamTallImageBlock,
    .WeAreYMCStartupHackatonTeamShortImageBlock {
        justify-content: space-around;
        padding-block: 2%;
    }

    .WeAreYMCStartupHackatonTeamImagesFlex {
        margin-top: 0%;
    }

    .WeAreYMCStartupTeamShortImageBlockReverse {
        flex-direction: row-reverse;
        border-top-left-radius: 0rem;
        border-bottom-left-radius: 0rem;
        border-top-right-radius: 12rem;
        border-bottom-right-radius: 12rem;
    }

    .WeAreYMCStartupTeamImage {
        width: 100%;
        height: auto;
    }

    .WeAreYMCStartupTeamImageSlightOffset {
        object-position: 0rem 0vw;
    }

    .WeAreYMCStartupTeamImageContainer,
    .WeAreYMCStartupHackatonTeamImageContainer2 {
        position:static;
        margin-top: 0%;
        margin-block: auto;
        width: 50%;
        max-width: 220px;
    }

    .WeAreYMCStartupHackatonTeamImageContainer2 {
        max-width: 160px;
    }

    .WeAreYMCStartupTeamMemberTexts {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-block: 5%;
    }

    .WeAreYMCStartupTeamMemberName,
    .WeAreYMCStartupTeamMemberName2 {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-block: 1rem;
        margin-block: 0px;
        font-size: 1.5rem;
    }
    
    .WeAreYMCStartupTeamMemberSpecialty {
        padding-block: 1rem;
        font-size: 1.2rem;
    }

    .WeAreYMCStartupSecondBlock{
        padding-top: 0px;
        background-color: #330066;
    }

    #Team6 {
        background-color: #EFEFFF;
    }
}

@media (max-width: 520px) {
    .WeAreYMCOurSolutionsSolutionBlock {
        width: 95%;
        margin-block-end: 1rem;
    }

    .WeAreYMCOurSolutionsSubtitle {
        width: 100%;
    }

    .WeAreYMCStartupTitle {
        width: 95%;
    }

    .WeAreYMCFirstBlockTrustedLogosBlock {
        width: 95%;
        margin-block: 3rem;
    }

    .WeAreYMCFirstBlockTrustedLogosImage {
        width: 15rem;
    }

    .WeAreYMCDidYouKnowTitle {
        font-size: 2rem;
        line-height: 1.8rem;
    }

    .WeAreYMCOurMissionLeftBlockDiv {
        width: 100%;
    }
    
    .WeAreYMCOurMissionRightBlock {
        width: 100%;
        margin-block-start: 2.5rem;
    }

    .WeAreYMCOurMissionTitle {
        padding-inline: 5%;
    }

    .WeAreYMCOurMissionLeftBlock {
        width: 70%;
        height: auto;
        margin-inline: auto;
    }

    .WeAreYMCOurMissionUpTitle {
        margin-inline: auto;
    }

    .WeAreYMCOurMissionTitle {
        text-align: center;
    }

    .WeAreYMCSolutionsLeftBlockSecondBlockTitle,
    .WeAreYMCSolutionsLeftBlockSecondBlockSubtitle {
        width: 100%;
        max-width: 100%;
    }

    .WeAreYMCSolutionsLeftBlockSecondBlockTitle {
        font-size: 2.3rem;
        line-height: 2.6rem;
    }

    .WeAreYMCSolutionsLeftBlockSecondBlockFlexImageContainer {
        display: none;
    }

    .WeAreYMCSolutionsLeftBlockSecondBlockFlexText2,
    .WeAreYMCSolutionsLeftBlockSecondBlockFlexText {
        width: 100%;
        padding-block-start: 1rem;
    }

    .WeAreYMCYouCanTrustBlock {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .WeAreYMCStartupTitle {
        line-height: 2.6rem;
    }

    .WeAreYMCOurJourneyIcon {
        width: 50px;
    }
    
    .WeAreYMCOurJourneyIconBigger {
        width: 83px;
    }

    .WeAreYMCOurJourneyFlex {
        gap: 10px
    }
}