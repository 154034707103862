.UserWelcomeResultsUpBannerMeter {
    width: 100%;
    height: 55px;
    position: relative;
    max-width: 630px;
    margin-inline: auto;
}

.UserWelcomeResultsUpBannerMeterFace {
    height: auto;
    width: 100px;
    bottom: -30px;
    position: absolute;
}

.UserWelcomeBlockRadarGraph {
    max-width: 500px;
    max-height: 500px;
    width: 100%;
    height: auto;
    margin-inline: auto;
}

.UserWelcomeBlockResultsTitle {
    font-size: 2.5rem;
    line-height: 2.6rem;
    font-weight: 600;
    font-family: 'Meltmino', sans-serif;
    color: #6633FF;
    text-align: center;
    margin-block-start: 3rem;
    margin-block-end: 1rem;
}

.UserWelcomeBlockResultsSubtitle {
    font-size: var(--epigraphText);
    font-family: 'Meltmino', sans-serif;
    color: #330066;
    text-align: center;
}

.UserWelcomeResultsUpBanner {
    background-color: #FFFAEA;
    border-radius: 15px;
    padding-block: 1rem;
    padding-inline: 2rem;
}

.UserWelcomeResultsInnerUpBanner {
    display: flex;
}

.UserWelcomeResultsUpBannerSubtitle {
    color: #330066;
}

.UserWelcomeResultsUpBannerUpTitle {
    font-family: 'Meltmino', sans-serif;
    color: #6633FF;
    margin-block-start: 1rem;
    letter-spacing: 1px;
}

.UserWelcomeResultsUpBannerTitle {
    font-family: 'Meltmino', sans-serif;
    font-size: 2.5rem;
    line-height: 2.6rem;
    font-weight: 600;
    text-align: center;
    color: #6633FF;
    margin-block: 1rem;
}

.UserWelcomeResultsHealthFactorsBlocks {
    margin-block: 1rem;
    max-width: 600px;
    margin-inline: auto;
}

.UserWelcomeResultsHealthFactorsPaddingBlock {
    padding-block-end: 1rem;
}

@media (max-width: 540px) {
    .UserWelcomeResultsUpBannerMeterFace {
        height: auto;
        width: 70px;
        bottom: -10px;
        position: absolute;
    }
}