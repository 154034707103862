.VerifyEmailFull {
    background-color: #EFEFFF;
    padding-inline: 2%;
    height: 100%;
    min-height : 100vh;
    padding-block: 2%;
}
.VerifyEmailInnerBox3 {
    margin-block: 12%;
    max-width: 800px;
    width: 80vw;
    margin-inline: auto;
    background-color: #FBFFFF;
    border: 1px solid #BFD4E4;
    border-radius: 15px;
    padding-inline: 5%;
    padding-block: 2rem;
    text-align: center;
    height: fit-content;
}

.VerifyEmailInnerBoxEmailVerification {
    color: #330066;
}

.VerifyEmailInnerBoxEmailVerificationText1 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-block-end: 1rem;
}

.VerifyEmailInnerBoxEmailVerificationText2 {
    font-size: 1.1rem;
}

.VerifyEmailInnerBoxEmailVerificationInputs {
    margin-block: 2rem;
}