.SignInRoutePage {
  height: 100%;
  min-height : 100vh;
  background-color: #EFEFFF;
  padding-block-start: var(--topPageMargin);
}

.SignInGeneralFlex {
  display: flex;
  max-width: 1214px;
}

.SignInLeftSide {
  width: 70%;
  margin-block: auto;
  text-align: center;
  color: #6633FF;
}

.SignInLeftSideTitle {
  font-size: 2.3rem;
  max-width: 20rem;
  margin-inline: auto;
  letter-spacing: 2px;
  font-family: 'Meltmino', sans-serif;
}

.SignInLeftSideSubtitle {
  margin-block: 1rem;
  font-weight: 500;
  font-size: 1rem;
  max-width: 35rem;
  margin-inline: auto;
  padding-inline: 2%;
}

.SignInLeftSideTitleInnerFlex {
  display: flex;
  justify-content: center;
  margin-block-start: 4rem;
}

.SignInLeftSideTitleInnerFlexedBlock {
  width: 9rem;
  margin-inline: 3%;
}

.SignInLeftSideTitleInnerFlexedBlockImage {
  width: 100%;
  height: auto;
}

.SignInLeftSideTitleInnerFlexedBlockText {
  font-size: 0.8rem;
  font-weight: 800;
  margin-block-start: 1rem;
}

.SignInContainer {
  padding-inline: 2%;
  padding-block: 2%;
}

.SignInInnerBox,
.SignInInnerBox2,
.SignInInnerBox3 {
  max-width: 500px;
  margin-inline: auto;
  background-color: #FBFFFF;
  border: 1px solid #BFD4E4;
  border-radius: 15px;
  padding-inline: 2%;
  padding-block: 2rem;
  height: fit-content;
}

.SignInInnerBox {
  width: 25rem;
}

.SignInInnerBox2 {
  max-width: 900px;
  color:#330066;
  padding-inline: 2%;
  padding-block: 1.5rem;
}

.SignInInnerBox3 {
  margin-block: 12%;
  max-width: 800px;
}

.SignInInnerBoxUptitle {
  color: #6633FF;
  font-size: var(--epigraphText);
  font-family: 'Meltmino', sans-serif;
}

.SignInInnerBoxTitle,
.SignInInnerBoxTitle2 {
  font-size: 2.5rem;
  letter-spacing: 1px;
  line-height: 3rem;
  color: #6633FF;
  font-weight: 600;
  padding-block: 0.8rem;
  font-family: 'Meltmino', sans-serif;
}

.SignInInnerBoxTitle2 {
  font-size: 2rem;
  padding-block: 0rem;
  margin-block: auto;
  line-height: 2.2rem;
}

.SignInInnerBox2UpperFlex {
  display: flex;
  background-color: #EFEFFF;
  border-radius: 10px;
  padding-inline: 1rem;
  padding-block: 1rem;
  margin-block-end: 0.5rem;
}

.SignInInnerBox2UpperFlexYMCIcon {
  height: 50px;
  width: auto;
  margin-block: auto;
  margin-inline-end: 4%;
}

.SignInInnerBoxSubtitle2 {
  color:#330066;
  font-size: 0.8rem;
  max-width: 25rem;
  font-size: var(--generalText);
  margin-block-start: 0.5rem;
}

.SignInInnerBoxTabsFlex {
  display: flex;
  margin-block-end: 1rem;
}

.SignInInnerBoxTab {
  padding-block: 1rem;
  color: #330066;
  font-weight: 400;
  font-size:  var(--buttonText);
  text-align: center;
  width: 50%;
  border-bottom: 1px solid #BFD4E4;
}

.SignInInnerBoxActiveTab {
  border-bottom: 3px solid #6633FF;
  background-color: #FFFFFF;
}

.SignInInnerBoxInputBlock {
  color: #330066;
  margin-block-start: 1rem;
  margin-block-end: 2rem;
}

.SignInInnerBoxInputBlockText {
  font-weight: 400;
  text-align: left;
  margin-block-end: 0.2rem;
}

.SignInInnerBoxInputTile {
  border: 1px solid #CFF9FF;
  width: 100%;
  padding-block: 0.5rem;
  padding-inline: 1rem;
  font-size:  var(--questionsText);
}

.SignInInnerBoxGoogleButton,
.SignInInnerBoxLoginButton,
.SignInInnerBoxGoogleButtonRegister {
  background-color: #CFF9FF;
  text-align: center;
  width: 100%;
  border-radius: 10px;
  color: #330066;
  font-weight: 500;
  font-size:  var(--buttonText);
  padding-inline: 2%;
  padding-block: 1rem;
  margin-block-start: 1rem;
  cursor: pointer;
  border: 2px solid #CCCCFF;
  height: 60px
}

.SignInInnerBoxGoogleButtonRegister {
  max-width: 20rem;
  margin-inline: auto;
}

.SignInInnerBoxLoginButton {
  background-color: #6633FF;
  color: white;
}

.SignInInnerBoxGoogleButton:hover,
.SignInInnerBoxLoginButton:hover,
.SignInInnerBoxGoogleButtonRegister:hover {
  background-color: #CCCCFF;
  color: #6633ff;
  transition: all 0.3s;
  border: 2px solid #6633FF;
}

.SignInAlert {
  color: red;
  text-align: left;
}

.SignInAlert2 {
  color: red;
  text-align: center;
  margin-block-start: 0.5rem;
}

/* Register user block */
.SignInInnerBoxGoogleRegisterDiv {
  margin-block: 1rem;
}

.SignInInnerBoxUserDataFlex {
  display: flex;
  justify-content: space-between;
}

.SignInInnerBoxUserDataTitle {
  font-size: 2rem;
  color: #6633FF;
  font-weight: 600;
  text-align: left;
  margin-block: auto;
  font-family: 'Meltmino', sans-serif;
}

.SignInInnerBoxUserDataTitlePersonIcon {
  width: 50px;
  height: 50px;
  margin-block: auto;
}

.SignInInnerBoxUserDataSubtitle,
.SignInInnerBoxGoogleRegisterText {
  text-align: left;
  margin-block: 1rem;
  font-size: var(--generalText);
}

.SignInInnerBoxInputBlocksFlex {
  display: flex;
  justify-content: space-between;
}

.SignInInnerBoxInputInnerBlock {
  color: #330066;
  margin-block: 1rem;
  width: 48%;
}

.SignInInnerBoxInputBlockGenderTilesFlexed {
  display: flex;
  justify-content: space-between;
}

.SignInInnerBoxInputBlockGenderTile {
  width: 48%;
  font-size: 1rem;
  height:fit-content
}

.SignInInnerBoxInputBlockGenderTileIcon {
  width: 25px;
  height: 25px;
  opacity: 0.3;
}

.SignInInnerBoxEmailVerification {
  color: #330066;
}

.SignInInnerBoxEmailVerificationText1 {
  font-size: 1.2rem;
  font-weight: 500;
  margin-block-end: 1rem;
}

.SignInInnerBoxEmailVerificationText2 {
  font-size: 1.1rem;
}

.SignInInnerBoxEmailVerificationInputs {
  margin-block: 2rem;
}

.SignInInnerBoxLastRegisterText {
  color: #330066;
  margin-block-start: 1rem;
  text-align: center;
  font-size: var(--epigraphText);
}

@media (max-width:800px) {
  .SignInGeneralFlex {
    flex-flow: row wrap-reverse;
  }

  .SignInLeftSide {
    width: 100%;
    margin-block-start: 2rem;
  }

  .SignInInnerBox {
    width: 100%;
    padding-inline: 5%;
  }

  .SignInLeftSideTitleInnerFlex {
    flex-flow: row wrap;
    margin-block-start: 3rem;
  }
  
  .SignInLeftSideTitleInnerFlexedBlock {
    width: 9rem;
    margin-block: 1rem;
  }

  .SignInInnerBoxInputBlocksFlex {
    flex-flow: row wrap;
  }

  .SignInInnerBoxInputInnerBlock {
    width: 100%;
    margin-block: 0.5rem;
  }

  .SignInInnerBox2 {
    padding-block: 1rem;
    padding-inline: 1rem;
  }

  .SignInInnerBox2UpperFlex {
    padding-inline: 0.5rem;
  }
}
